<script setup lang="ts">
import type { Locale } from 'vue-i18n'
import type {
  Collections,
  ContentNavigationItem,
  ContentPageAllEnCollectionItem,
} from '@nuxt/content'

type Article = {
  title: ContentNavigationItem['title']
  path: ContentNavigationItem['path']
  stem: ContentNavigationItem['stem']
  image: ContentPageAllEnCollectionItem['image']
  lastmod: NonNullable<ContentPageAllEnCollectionItem['sitemap']>['lastmod']
}

const props = withDefaults(
  defineProps<{
    path?: string
    locale?: Locale
  }>(),
  {
    path: '/blog/articles',
  },
)
const { locale: _locale } = useI18n()
const loc = computed(() => props.locale || _locale.value)

const { data: navigation } = await useAsyncData(
  'content:blog-post-list:' + props.path,
  async () => {
    const collection = getContentCollection('blog', loc.value).name as keyof Collections
    const content = await queryCollectionNavigation(collection, ['image', 'sitemap'])
      .where('path', 'LIKE', `${props.path}%`)
      .order('stem', 'ASC')
    return content
  },
  { watch: [loc] },
)

function findObjectsByPath(
  data: ContentNavigationItem[],
  targetPath: string,
): ContentNavigationItem[] {
  if (targetPath === '/') return data
  const result: ContentNavigationItem[] = []
  function search(node: ContentNavigationItem) {
    if (node.path === targetPath) result.push(node)
    if (node.children) node.children.forEach(search)
  }
  data.forEach(search)
  return result
}

function filterValidPages(data: ContentNavigationItem[]): ContentNavigationItem[] {
  return data.filter((item) => item.page !== false)
}

function getChildren(): ContentNavigationItem[] {
  if (navigation.value) {
    const r = findObjectsByPath(navigation.value, props.path)
    if (r[0] && r[0].children) return filterValidPages(r[0].children)
  }
  return []
}

function normalizeArticles(data: ContentNavigationItem[]): Article[] {
  return data.map((item) => ({
    title: item.title,
    path: item.path,
    stem: item.stem,
    image: item.image as ContentPageAllEnCollectionItem['image'],
    lastmod: (item.sitemap as ContentPageAllEnCollectionItem['sitemap'])?.lastmod,
  }))
}

function displayDate(dateString: string | undefined) {
  if (!dateString) return ''
  const date = new Date(dateString)
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

const articles = computed<Article[]>(() => {
  return normalizeArticles(getChildren()).sort((a, b) => {
    return a.lastmod! > b.lastmod! ? -1 : 1
  })
})
</script>

<template>
  <nav>
    <ul>
      <li v-for="item of articles" :key="item.path" class="listItem">
        <img v-if="item.image" :src="item.image" alt="" class="thumbnail" />
        <div style="flex: 1">
          <!-- eslint-disable-next-line vuetify/no-deprecated-classes -->
          <NuxtLink v-if="item.path" :to="localeLink(item.path, loc)" class="title">{{
            item.title
          }}</NuxtLink>
          <div class="date">{{ displayDate(item.lastmod) }}</div>
        </div>
      </li>
    </ul>
  </nav>
  <!-- <DebugDumpArray :items="articles" /> -->
</template>

<style scoped>
ul {
  margin: 0;
  padding: 0;
}
.listItem {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping for other elements */
  margin-bottom: 2em;
}
.date {
  color: #999;
  flex-wrap: nowrap;
  flex: 0 0 auto; /* Prevent shrinking and set to auto width */
  width: 6.5em; /* Set width */
  font-size: 1em;
}
.title {
  flex: 1; /* Allow other columns to grow and shrink */
  font-weight: 500;
  font-size: 1.1em;
}
.thumbnail {
  height: 6em;
  width: 6em;
  object-fit: cover;
  margin-right: 1em;
  border-radius: 0.5em;
}
</style>
